import React from 'react';
import withStore from "@/hocs/withStore";
import LinkLoad from "@/Layouts/LinkLoad";

function MobileSubMenu(props) {
    const {stores = {}, soulSubMenu = []} = props;
    const {subMenuStore = {}, mobileMenuStore = {}} = stores;

    let element = soulSubMenu.map((el) => {
        return <div key={el.id} className={`nav__item`}>
            <LinkLoad href={`${el.link}`}
                  onClick={() => mobileMenuStore.closeMobileMenu()}
            >
                {el.name}
            </LinkLoad>
        </div>
    });

    return <>
        <div onClick={() => subMenuStore.goBackToMenu('soul')}
             className={`nav__item`}>
            Назад
        </div>
        {element.length !== 0 && (element)}
    </>
}

export default withStore(MobileSubMenu)
